<template>
  <div class="container" v-loading="loading">
    <content-title :nav="nav"> </content-title>
    <div class="flex time-part">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        @change="changeTime"
      >
      </el-date-picker>
      <el-tabs class="m-l20" v-model="activeName" @tab-click="tabChange">
        <el-tab-pane
          v-for="(item, index) in tabslist"
          :key="index"
          :label="item"
          :value="index"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 统计 -->
    <div class="flex">
      <div class="card flex">
        <div class="card-img">
          <svg-icon name="money" color="#ff7e00" size="36"></svg-icon>
        </div>
        <div class="">
          <div class="card-count">￥{{ totalData.incomeTotal }}</div>
          <div class="card-name">总支付订单金额</div>
        </div>
      </div>
      <div class="card flex">
        <div class="card-img">
          <svg-icon name="order" color="#ff7e00" size="36"></svg-icon>
        </div>
        <div class="">
          <div class="card-count">{{ totalData.incomeNum }}</div>
          <div class="card-name">总支付订单数</div>
        </div>
      </div>
      <div class="card flex">
        <div class="card-img">
          <svg-icon name="order" color="#5085f2" size="36"></svg-icon>
        </div>
        <div class="">
          <div class="card-count">{{ todayData.expendNum }}</div>
          <div class="card-name">{{ tabName }}支出订单数</div>
        </div>
      </div>
      <div class="card flex">
        <div class="card-img">
          <svg-icon name="money" color="#5085f2" size="36"></svg-icon>
        </div>
        <div class="">
          <div class="card-count">￥{{ todayData.expendTotal }}</div>
          <div class="card-name">{{ tabName }}支出金额</div>
        </div>
      </div>
    </div>
    <div class="title1">下单渠道来源分析</div>

    <div class="flex" style="width: 100%">
      <div class="flex1">
        <div class="title2">订单总数</div>
        <div style="height: 350px">
          <circleEcharts ename="orderTotal" :data="allList"></circleEcharts>
        </div>
      </div>
      <div class="flex1">
        <div class="title2">{{ tabName }}订单</div>
        <div style="height: 350px">
          <circleEcharts ename="dayOrder" :data="todayList"></circleEcharts>
        </div>
      </div>
      <div class="flex1">
        <div class="title2">{{ tabName }}订单类型</div>
        <div style="height: 350px">
          <circleEcharts ename="orderType" :data="typeList"></circleEcharts>
        </div>
      </div>
    </div>

    <div>
      <div>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="订单号">
            <el-input v-model="orderNo" placeholder="输入订单号"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="mobile"
              placeholder="输入下单用户手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="type" clearable>
              <el-option
                v-for="(item, index) in typeSearchList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getRefoundList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <el-form label-position="left">
              <el-form-item label="付款方式">
                <span>{{ Paytype(row) }}</span>
              </el-form-item>
              <el-form-item label="订单类型">
                <span>{{ getorderType(row.rcharge_type) }}</span>
              </el-form-item>
              <el-form-item label="支付时间">
                <span>{{ row.finished_time }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单号" width="150">
          <template v-slot="{ row }">
            <p class="textell">{{ row.flowing_no }}</p>
          </template>
        </el-table-column>

        <el-table-column align="center" label="商品名称">
          <template v-slot="{ row }">
            {{ getName(row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="course_num"
          align="center"
          label="课时数"
        ></el-table-column>
        <el-table-column align="center" label="用户昵称">
          <template v-slot="{ row }">
            <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }">
              {{ row.map.userinfo.nick_name }}</link-to
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
          label="订单渠道"
          :formatter="Paysource"
        ></el-table-column>
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间"
          width="200px"
        ></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #6f7174"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #6f7174"
              >待付款</span
            >
            <span v-if="scope.row.status == 8" style="color: #6f7174"
              >已取消</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <link-to :to="'orderDetails/' + row.id">
              <p>详情</p>
            </link-to>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="total"
      ></paging-fy>
    </div>
  </div>
</template>

<script>
import circleEcharts from "../../components/echarts/circleEcharts.vue";
import { getorderType, orderType } from "@/utils/map/orderType.js";
export default {
  components: {
    circleEcharts,
  },
  data() {
    return {
      nav: { firstNav: "订单管理中心", secondNav: "数据总览" },
      todayInfo: {},
      todayTotal: 0,
      todayList: [],
      allInfo: {},
      allTotal: 0,
      allList: [],
      typeList: [],
      //统计处理后的数据
      totalData: {
        incomeTotal: 0,
        expendTotal: 0,
        incomeNum: 0,
        expendNum: 0,
        incomeList: [],
        expendList: [],
      },
      todayData: {
        incomeTotal: 0,
        expendTotal: 0,
        incomeNum: 0,
        expendNum: 0,
        incomeList: [],
        expendList: [],
      },
      typeSearchList: [
        {
          value: "study_card",
          label: "课程",
        },
        {
          value: "member",
          label: "会员卡",
        },
        {
          value: "point",
          label: "权益点",
        },
        {
          value: "consume",
          label: "消费金",
        },
        {
          value: "coupon",
          label: "优惠券",
        },
        {
          value: "coach_need",
          label: "教练课包",
        },
        {
          value: "need_bond",
          label: "需求保证金",
        },
        {
          value: "venue",
          label: "场馆服务",
        },
      ],
      activeName: 0,
      tabslist: ["今日", "本周", "本月"],
      tabName: "今日",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近7天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: [new Date().Format("yyyy-MM-dd"), new Date().Format("yyyy-MM-dd")],
      start_time: new Date().Format("yyyy-MM-dd"),
      end_time: new Date().Format("yyyy-MM-dd"),
      loading: true,
      //订单列表
      total: 0,
      list: [],
      currentPage: 1,
      type: "",
      mobile: "",
      orderNo: "",
      source: "",
    };
  },

  mounted() {
    this.getAllType();
    this.getRefoundList();
    this.getAllInfo();
    this.getTodayInfo();
    this.assets_total();
    this.typeSearchList = orderType.filter((item) => item.income);
  },

  methods: {
    getorderType(type) {
      return getorderType(type).label;
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    //查询订单
    getRefoundList() {
      let url = "/user/rechargerecord/queryByMessage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: 2,
        start_time: this.start_time + " 00:00:00",
        end_time: this.end_time + " 23:59:59",
        login_name: this.mobile || null,
        flowing_no: this.orderNo || null,
        source: this.source || null,
      };
      if (this.type) {
        params.rcharge_type = this.type;
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.total = res.data.data.total;
          this.list = res.data.data.rows;
        });
    },
    Paytype(row, column) {
      let type = row.pay_type;
      if (type == "ali") {
        return "支付宝";
      } else if (type == "wx") {
        return "微信";
      } else if (type == "point") {
        return "权益点";
      } else if (type == "dy") {
        return "抖音";
      } else {
        return type;
      }
    },
    Paysource(row) {
      let source = row.source;
      let pay = row.pay_type;
      if (source == "sport" && pay == "dy") {
        return "抖音小程序";
      } else if (source == "sport" && pay != "dy") {
        return "微信小程序";
      } else if (source == "coach_sport") {
        return "教练小程序";
      } else {
        return source;
      }
    },
    getName(row) {
      if (row.title) {
        return row.title;
      }
      if (row.map.masterSetPriceEntity) {
        return row.map.masterSetPriceEntity.title;
      }
      return "";
    },
    toOrderList(v) {
      this.$router.push({
        name: "allOrderList",
        params: {
          type: v,
        },
      });
    },
    // 时间选择
    changeTime(v) {
      this.start_time = v[0];
      this.end_time = v[1];
      this.activeName = "4";
      this.getTodayInfo();
      this.assets_total();
      this.getAllType();
      this.getRefoundList();
      this.tabName = "";
    },
    // tab切换
    tabChange(val) {
      this.date = [];
      this.tabName = val.label;
      switch (val.label) {
        case "今日":
          this.start_time = new Date().Format("yyyy-MM-dd");
          break;
        case "本周":
          this.start_time = this.getNowWeek();
          break;
        case "本月":
          this.start_time = this.getCurrentMonthFirst();
          break;
        default:
          break;
      }
      this.date[0] = this.start_time;
      this.date[1] = this.end_time;
      this.getTodayInfo();
      this.assets_total();
      this.getAllType();
      this.getRefoundList();
    },
    //本月
    getCurrentMonthFirst() {
      var date = new Date();
      return new Date(date.setDate(1)).Format("yyyy-MM-dd");
    },
    // 获取本周
    getNowWeek(s) {
      let curDay = s ? new Date(s).getDay() : new Date().getDay();
      let start = s ? new Date(s).getTime() : new Date().getTime();
      let week = [];
      for (let i = 1 - curDay; i < 8 - curDay; i++) {
        let time = new Date(start + i * 1000 * 24 * 60 * 60);
        let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        week[data.week] = data;
      }
      return week[1].time;
    },
    //订单类型
    getAllType() {
      let params = {
        start_time: this.start_time + " 00:00:00",
        end_time: this.end_time + " 23:59:59",
        finished: true,
      };
      this.$axios({
        url: "user/rechargerecord/orderTypeStatistics",
        params,
      }).then((res) => {
        this.typeList = [];
        let result = res.data.data;
        if (result.length == 0) {
          result = [
            { rcharge_type: "study_card", count: 0 },
            { rcharge_type: "consume", count: 0 },
            { rcharge_type: "member", count: 0 },
            { rcharge_type: "venue", count: 0 },
          ];
        }
        result.forEach((item) => {
          this.typeList.push({
            name: getorderType(item.rcharge_type).label,
            value: item.count,
          });
        });
      });
    },
    //全部订单
    getAllInfo() {
      let params = {
        start_time: "2022-01-01 00:00:00",
        end_time: new Date().Format("yyyy-MM-dd") + " 23:59:59",
        finished: true,
      };
      this.$axios({
        url: "user/rechargerecord/orderCountStatistics",
        params,
      }).then((res) => {
        this.allInfo = res.data.data;
        let todayName = {
          sport: "微信小程序",
          app: "APP",
          pc: "PC",
          dy: "抖音小程序",
        };
        let allList = [];
        for (let key in this.allInfo) {
          this.allTotal += this.allInfo[key];
          allList.push({
            name: todayName[key],
            value: this.allInfo[key],
          });
        }
        this.allList = allList;
      });
    },
    getTodayInfo() {
      let params = {
        start_time: this.start_time + " 00:00:00",
        end_time: this.end_time + " 23:59:59",
        finished: true,
      };
      this.$axios({
        url: "user/rechargerecord/orderCountStatistics",
        params,
      }).then((res) => {
        this.todayInfo = res.data.data;
        let todayName = {
          sport: "微信小程序",
          app: "APP",
          pc: "PC",
          dy: "抖音小程序",
        };
        let todayList = [];
        for (let key in this.todayInfo) {
          this.todayTotal += this.todayInfo[key];
          todayList.push({
            name: todayName[key],
            value: this.todayInfo[key],
          });
        }
        this.todayList = todayList;
      });
    },
    //总资产统计
    assets_total() {
      let url = "user/rechargerecord/totalAssetsCount";
      let params = {
        // statistics_time: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        start_time: this.start_time + " 00:00:00",
        end_time: this.end_time + " 23:59:59",
      };
      this.$axios
        .get(url, { params: params })
        .then((res) => {
          this.loading = false;
          let result = res.data.data;
          let resTotal = result.statisticsTotal;
          let resToday = result.statisticsToday;
          this.totalData = this.handlerData(resTotal);
          this.todayData = this.handlerData(resToday);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handlerData(list) {
      let incomeTotal = 0;
      let expendTotal = 0;
      let incomeNum = 0;
      let expendNum = 0;
      let incomeList = [];
      let expendList = [];
      list.forEach((item) => {
        let type = getorderType(item.rcharge_type);
        type.num = item.total_num;
        type.price = item.total_Acount;
        if (!type.notQuery) {
          if (type.income) {
            incomeTotal += item.total_Acount;
            incomeNum += item.total_num;
            incomeList.push(type);
          } else {
            expendTotal += item.total_Acount;
            expendNum += item.total_num;
            expendList.push(type);
          }
        }
      });
      return {
        incomeTotal: incomeTotal.toFixed(2),
        expendTotal: expendTotal.toFixed(2),
        incomeNum: incomeNum.toFixed(0),
        expendNum: expendNum.toFixed(0),
        incomeList,
        expendList,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
}

.card {
  padding: 15px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
  width: fit-content;
  margin-right: 50px;
  border-radius: 5px;

  &:hover {
    cursor: default;
    transform: scale(1.05);
    transition: all 0.2s;
  }

  .card-img {
    height: 40px;
    margin-right: 10px;
  }

  .card-count {
    font-size: 20px;
    color: #434343;
  }

  .card-name {
    font-size: 14px;
    color: #808080;
  }
}

.time-part {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title1 {
  font-size: 18px;
  color: #434343;
  margin: 24px 0;
}

.title2 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-weight: 500;
}

.flex1 {
  flex: 1;
}

.details-title-wrap {
  margin-top: 20px;

  .details-title {
    flex: 1;
    font-size: 18px;
  }
}

.details-item {
  width: 50%;

  .item-title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .details-list {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 14px;

    .no-data {
      padding-left: 150px;
      color: #808080;
      padding-top: 20px;
    }

    .list-item {
      display: flex;
      align-items: center;
      height: 30px;

      &:hover {
        cursor: pointer;
        color: #7daaf1;
      }

      .rect {
        width: 10px;
        height: 10px;
        background-color: rgb(22, 96, 165);
      }

      p {
        min-width: 100px;
      }

      .name {
        margin-left: 10px;
      }

      .num {
        margin-left: 20px;
      }

      .price {
        margin-left: 20px;
      }
    }
  }
}
</style>