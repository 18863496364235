<template>
  <div :ref="ename" :style="{width,height}"></div>
</template>
  
  <script>
export default {
  props: {
    data: {
      type: Array,
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default:"100%"
    },
    height: {
      type: String,
      default:"100%"
    },
  },
  data() {
    return {
      myChart: {},
      list: [],
      color: ["#8d7fec", "#5085f2", "#FF7E00", "#f87be2", "#f2719a"],
      xdata: [],
      ydata: [],
      option: {
        backgroundColor: "rgba(255,255,255,1)",
        color: [],
        legend: {
          orient: "vartical",
          x: "left",
          top: "center",
          left: "60%",
          bottom: "0%",
          data: [],
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 16,
          formatter: (name) => {
            return "" + name + "   " + this.getValue(name);
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["40%", "70%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              //图形样式
              normal: {
                borderColor: "#ffffff",
                borderWidth: 6,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{text|{b}}\n{c} ({d}%)",
                rich: {
                  text: {
                    color: "#666",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#8693F3",
                    fontSize: 24,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 24,
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  computed:{
    full(){
      return this.$store.state.menuShow
    }
  },
  watch: {
    full(newval){
      this.myChart.resize()
    },
    data: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getValue(v) {
      let res = 0;
      this.data.forEach((item) => {
        if (item.name == v) res = item.value;
      });
      return res;
    },
    updateOption() {
      this.xdata = this.list.map((item) => {
        return item.name;
      });
      this.ydata = this.list;
      this.option.color = this.color;
      this.option.legend.data = this.xdata;
      this.option.series[0].data = this.ydata;
      this.myChart.setOption(this.option);
    },
    getEchartData() {
      this.xdata = this.list.map((item) => {
        return item.name;
      });
      this.ydata = this.data;
      this.option.legend.data = this.xdata;
      this.option.series[0].data = this.ydata;
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>